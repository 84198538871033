<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="9">
          <b-card-group>
            <b-card
              no-body
              class="text-white py-8 kenburns-left d-md-down-none card-register"
            >
              <div class="text-center center-abs">
                <div>
                  <div>
                    <h1 class="text-white mb-3">
                      <b>Inicia sesión</b>
                    </h1>
                  </div>
                  <div>
                    <b-button
                      @click="go_login"
                      class="md-secondary md-width rounded-pill p-1"
                      >INICIAR SESIÓN</b-button
                    >
                  </div>
                </div>
              </div>
            </b-card>
            <b-card
              no-body
              class="bg-gray-default card-register-login bg-white"
            >
              <ul class="list-unstyled multi-steps mt-1">
                <li :class="[step == 0 ? 'is-active' : '']">Primer Paso</li>
                <li :class="[step == 1 ? 'is-active' : '']">Segundo Paso</li>
                <li :class="[step == 2 ? 'is-active' : '']">Tercer Paso</li>
              </ul>
              <b-card-body class="px-4 pb-2">
                <validation-observer ref="registerForm">
                <b-form @submit.prevent="createAccount" class="m-2">
                  <loading
                    :active.sync="isLoading"
                    :can-cancel="false"
                    :is-full-page="fullPage"
                  ></loading>
                  <div v-if="step != 2">
                    <center>
                      <h3>Registrate</h3>
                      <div>
                        <p>Por favor, completa los siguientes datos</p>
                      </div>
                    </center>
                  </div>

                  <!-- step 1 -->
                  <!-- <div v-if="step == 0"> -->
                    <validation-observer 
                      :key="1" 
                      v-if="step == 0"
                      #default="{invalid}"
                    >
                    <!-- email -->
                    <b-form-group label="Email" label-for="register-email">
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        vid="email"
                        rules="required|email"
                      >
                        <b-form-input
                          size="sm"
                          id="register-email"
                          v-model="user.email"
                          name="register-email"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Email"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- telefono -->
                    <b-form-group
                      label="Telefono"
                      label-for="register-telefono"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Telefono"
                        vid="telefono"
                        rules="required|min:5"
                      >
                        <b-form-input
                          size="sm"
                          id="register-telefono"
                          v-model="user.phone"
                          name="register-telefono"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Ingresa tu número de telefono"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- password -->
                    <b-form-group
                      label-for="register-password"
                      label="Contraseña"
                      description="puedes usar caracteres especiales, letras y numeros"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Password"
                        vid="password"
                        rules="required|min:5"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <b-form-input
                            size="sm"
                            id="register-password"
                            v-model="user.password"
                            class="form-control-merge"
                            type="password"
                            :state="errors.length > 0 ? false : null"
                            name="register-password"
                            autocomplete="off"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- password confirmation -->
                    <b-form-group
                      label-for="register-password-confirmation"
                      label="Confirmar Contraseña"
                      description="puedes usar caracteres especiales, letras y numeros"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Confirmar Contraseña"
                        rules="required|confirmed:password"
                      >
                        <b-input-group
                          label="Repetir Contraseña"
                          description="puedes usar caracteres especiales, letras y numeros"
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <b-form-input
                            size="sm"
                            v-model="user.password_confirmation"
                            class="form-control-merge"
                            type="password"
                            :state="errors.length > 0 ? false : null"
                            name="register-password-confirmation"
                            autocomplete="off"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-button
                      :disabled="invalid"
                      v-if="step == 0"
                      @click="step++"
                      class="md-primary md-width rounded-pill w-100 py-1"
                      >SIGUIENTE</b-button
                    >
                  </validation-observer>
                  <!-- </div> -->
                  <!-- fin step 1 -->

                  <!-- step 2 -->
                  <!-- <div v-if="step == 1"> -->
                    <validation-observer 
                      :key="2" 
                      v-if="step == 1"
                      #default="{invalid}"
                    >
                    <!-- Empresa -->
                    <b-form-group label="Empresa" label-for="register-empresa">
                      <validation-provider
                        #default="{ errors }"
                        name="Empresa"
                        vid="empresa"
                        rules="required|min:2"
                      >
                        <b-form-input
                          size="sm"
                          id="register-empresa"
                          v-model="user.brand"
                          name="register-empresa"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Nombre de la empresa"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- envios mensuales -->
                    <b-form-group
                      label="Envios Mensuales"
                      description="Cantidad aproximada de envíos al mes."
                      label-for="monthly_shipments"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Envios Mensuales"
                        vid="monthly_shipments"
                        rules="required"
                      >
                        <b-form-select
                          size="sm"
                          id="monthly_shipments"
                          name="monthly_shipments"
                          v-model="user.monthly_shipments"
                          :options="monthly_shipments"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- provincia -->
                    <b-form-group
                      label="Provincia"
                      label-for="register-provincia"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Provincia"
                        vid="provincia"
                        rules="required"
                      >
                        <b-form-select
                          size="sm"
                          id="register-provincia"
                          name="register-provincia"
                          v-model="user.province_id"
                          @input="fetchLocations"
                          :options="provinces"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- localidad -->
                    <b-form-group
                      label="Localidad"
                      label-for="register-localidad"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Localidad"
                        vid="localidad"
                        rules="required"
                      >
                        <b-form-select
                          size="sm"
                          id="register-localidad"
                          name="register-localidad"
                          v-model="user.location_id"
                          :options="locations"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  <!-- </div> -->
                  <div>
                    <b-row>
                      <b-col>
                        <b-button
                          v-if="step == 1"
                          @click="step -= 1"
                          class="md-secondary md-width rounded-pill w-100 py-1"
                          >VOLVER</b-button
                        >
                      </b-col>
                      <b-col>
                        <b-button
                          v-if="step == 1"
                          :disabled="invalid"
                          type="submmit"
                          class="md-primary md-width rounded-pill w-100 py-1"
                          >SIGUIENTE</b-button
                        >
                      </b-col>
                    </b-row>
                  </div>
                  </validation-observer>
                  <!-- fin step 2 -->

                  <!-- step 3 -->
                  <div v-if="step == 2" class="mb-5 mt-1">
                    <center>
                      <img
                        class="mt-1 mb-2"
                        src="/img/step/check1.png"
                      />
                      <div class="mb-5">
                        <h3>¡Felicidades!</h3>
                        <p>Tu registro fue exitoso. Te hemos enviado un email a tu correo para verificar la cuenta</p>
                      </div>
                    </center>
                  </div>
                  <!-- fin step 3 -->
                </b-form>
                </validation-observer>
                <b-button
                  v-if="step == 2"
                  @click="go_login()"
                  class="md-primary md-width rounded-pill w-100 py-1 mb-2 mt-5"
                  >FINALIZAR</b-button
                >
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow,
  BCol,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormSelect,
  BCardGroup,
  BCard,
  BCardBody,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import UserService from "../../../services/UserService.js";
import LocationService from "../../../services/LocationService";

export default {
  name: "Register",
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormSelect,
    BCardGroup,
    BCard,
    BCardBody,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      step: 0,
      isLoading: false,
      fullPage: false,
      required,
      email,
      locations: [],
      provinces: [],
      documents: [
        { text: "DNI", value: "dni" },
        { text: "CUIT", value: "cuit" },
      ],
      monthly_shipments: [
        {
          value: "10",
          text: "10",
        },
        {
          value: "20",
          text: "20",
        },
        {
          value: "50",
          text: "50",
        },
        {
          value: "100",
          text: "100",
        },
        {
          value: "400",
          text: "400",
        },
        {
          value: "+400",
          text: "+400",
        },
      ],
      user: {
        email: null,
        password: null,
        password_confirmation: null,
        brand: null,
        monthly_shipments: null,
        province_id: null,
      },
    };
  },
  methods: {
    /**
     *
     */
    createAccount() {
      let timerInterval;
      this.$refs.registerForm.validate().then((valid) => {
        if (valid) {
          // do stuff if not valid.
          this.isLoading = true;
          UserService.register(this.user)
            .then(() => {
              this.step += 1;
              this.isLoading = false;
              this.$swal({
                title:
                  "Registro exitoso se envio un email para la verificacion",
                type: "success",
                html: "seras redireccionado en <strong></strong> segundos.",
                timer: 5000,
                confirmButtonColor: "#f68d1e",
                onBeforeOpen: () => {
                  this.$swal.showLoading();
                  timerInterval = setInterval(() => {
                    this.$swal
                      .getContent()
                      .querySelector("strong").textContent = Math.floor(
                      this.$swal.getTimerLeft() / 1000
                    );
                  }, 100);
                },
                onClose: () => {
                  clearInterval(timerInterval);
                },
              }).then(() => {
                this.$router.push("/");
              });
            })
            .catch((error) => {
              const status = error.response.status;

              console.error(status);
              this.isLoading = false;
              if (status == 422) {
                const errors = error.response.data.errors;
                const keys = Object.keys(errors);
                keys.forEach((property) => {
                  errors[property].forEach((value) => {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Error`,
                        text: `${value}`,
                        icon: "AlertOctagonIcon",
                        variant: "danger",
                      },
                    });
                  });
                  this.user[property] = null;
                  this.$validator.flag(property, {
                    valid: false,
                    dirty: false,
                  });
                });
              } else if (error == 500) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Error`,
                    text: `Hubo un error al procesar la solicitud`,
                    icon: "AlertOctagonIcon",
                    variant: "danger",
                  },
                });
              }
            });
        }
      });
    },

    /**
     *
     */
    fetchLocations() {
      LocationService.locations({
        province_id: this.user.province_id,
      }).then(({ data }) => {
        this.locations = data.data;
        if (this.locations[0]) {
          this.user.location_id = this.locations[0].value;
        }
      });
    },

    /**
     *
     */
    fetchProvinces() {
      LocationService.provinces().then(({ data }) => {
        this.provinces = data.data;
      });
    },

    /**
     *
     */
    go_login() {
      this.$router.push("/login");
    },
  },
  mounted() {
    this.fetchProvinces();
  },
};
</script>
<style lang="scss">
.md-secondary {
  width: 150px;
  font-size: 14px !important;
  color: #f68d1e !important;
  background: white !important;
  font-weight: bold !important;
}

.md-secondary:focus {
  color: #f68d1e !important;
  background: white !important;
}

.md-secondary:hover {
  box-shadow: 0 8px 25px -8px #f68d1e;
}

.card-register {
  border: 0;
  border-radius: 1rem 0 0 1rem;
}
.bg-gray-default {
  background: #f3f3f3;
}
.md-primary {
  background-image: linear-gradient(
    to right,
    #f68d1e 5%,
    #f05822 100%
  ) !important;
}
.center-abs {
  position: relative !important;
  left: 0 !important;
  top: 200px !important;
}
.py-8 {
  height: auto;
  background-image: url("/img/auth_background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white !important;
}

.card-body h3 {
    line-height: 1.5em;
    color: #f6770d;
    font-size: 1.73125rem;
    font-weight: 700;
  }

.form-group label{
  font-weight: 700;
  font-size: 15px;
}

.card-register-login {
  border-radius: 0 1rem 1rem 0;
}

.btn-activated {
  padding: 6px 12px;
  border-radius: 50px;
  color: #fff;
  background-color: #f68d1e !important;
  border: 1px solid #f68d1e !important;
}

.btn-disabled {
  padding: 6px 12px;
  border-radius: 50px;
  color: rgb(189, 184, 184) !important;
  background-color: #fff !important;
  border: 1px solid rgb(189, 184, 184) !important;
}

.text-activated {
  font-size: 12px;
  margin-left: 4px;
  margin-top: 7px;
  color: gray;
}

.text-disabled {
  font-size: 12px;
  margin-left: 4px;
  margin-top: 7px;
  color: rgb(189, 184, 184);
}


/* STEPS */
$brand-primary: #f68d1e;
$white: #fff;
$grey-light: #ededed;
%remain-steps{
  &:before {
    content: counter(stepNum);
    font-family: inherit;
    font-weight: 700;
  }
  &:after{
    background-color: $grey-light;
  }
}
.multi-steps{
  display: table;
  table-layout: fixed;
  width: 100%;
  > li{
    counter-increment: stepNum;
    text-align: center;
    display: table-cell;
    position: relative;
    color: $brand-primary;

    &:before{
      content: '\f00c';
      content: '\2713;';
      content: '\10003';
      content: '\10004';
      content: '\2713';
      display: block;
      margin: 0 auto 4px;
      background-color: $white;
      width: 36px;
      height: 36px;
      line-height: 32px;
      text-align: center;
      font-weight: bold;
      border: {
        width: 2px;
        style: solid;
        color: $brand-primary;
        radius: 50%;
      }
    }
    &:after{
      content: '';
      height: 2px;
      width: 100%;
      background-color: $brand-primary;
      position: absolute;
      top: 16px;
      left: 50%;
      z-index: -1;
    }
    &:last-child{
      &:after{
        display: none;
      }
    }

    &.is-active{
      @extend %remain-steps;
      &:before{
        background-color: $white;
        border-color: $brand-primary;
      }

      ~ li{
        color: #808080;
        @extend %remain-steps;
        &:before{
          background-color: $grey-light;
          border-color: $grey-light;
        }
      }
    }
  }
}
</style>
