var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app flex-row align-items-center"},[_c('div',{staticClass:"container"},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"md":"9"}},[_c('b-card-group',[_c('b-card',{staticClass:"text-white py-8 kenburns-left d-md-down-none card-register",attrs:{"no-body":""}},[_c('div',{staticClass:"text-center center-abs"},[_c('div',[_c('div',[_c('h1',{staticClass:"text-white mb-3"},[_c('b',[_vm._v("Inicia sesión")])])]),_c('div',[_c('b-button',{staticClass:"md-secondary md-width rounded-pill p-1",on:{"click":_vm.go_login}},[_vm._v("INICIAR SESIÓN")])],1)])])]),_c('b-card',{staticClass:"bg-gray-default card-register-login bg-white",attrs:{"no-body":""}},[_c('ul',{staticClass:"list-unstyled multi-steps mt-1"},[_c('li',{class:[_vm.step == 0 ? 'is-active' : '']},[_vm._v("Primer Paso")]),_c('li',{class:[_vm.step == 1 ? 'is-active' : '']},[_vm._v("Segundo Paso")]),_c('li',{class:[_vm.step == 2 ? 'is-active' : '']},[_vm._v("Tercer Paso")])]),_c('b-card-body',{staticClass:"px-4 pb-2"},[_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"m-2",on:{"submit":function($event){$event.preventDefault();return _vm.createAccount.apply(null, arguments)}}},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":_vm.fullPage},on:{"update:active":function($event){_vm.isLoading=$event}}}),(_vm.step != 2)?_c('div',[_c('center',[_c('h3',[_vm._v("Registrate")]),_c('div',[_c('p',[_vm._v("Por favor, completa los siguientes datos")])])])],1):_vm._e(),(_vm.step == 0)?_c('validation-observer',{key:1,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","id":"register-email","name":"register-email","state":errors.length > 0 ? false : null,"placeholder":"Email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Telefono","label-for":"register-telefono"}},[_c('validation-provider',{attrs:{"name":"Telefono","vid":"telefono","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","id":"register-telefono","name":"register-telefono","state":errors.length > 0 ? false : null,"placeholder":"Ingresa tu número de telefono"},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"Contraseña","description":"puedes usar caracteres especiales, letras y numeros"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"size":"sm","id":"register-password","type":"password","state":errors.length > 0 ? false : null,"name":"register-password","autocomplete":"off"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-password-confirmation","label":"Confirmar Contraseña","description":"puedes usar caracteres especiales, letras y numeros"}},[_c('validation-provider',{attrs:{"name":"Confirmar Contraseña","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label":"Repetir Contraseña","description":"puedes usar caracteres especiales, letras y numeros"}},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"size":"sm","type":"password","state":errors.length > 0 ? false : null,"name":"register-password-confirmation","autocomplete":"off"},model:{value:(_vm.user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.user, "password_confirmation", $$v)},expression:"user.password_confirmation"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.step == 0)?_c('b-button',{staticClass:"md-primary md-width rounded-pill w-100 py-1",attrs:{"disabled":invalid},on:{"click":function($event){_vm.step++}}},[_vm._v("SIGUIENTE")]):_vm._e()]}}],null,false,3284272296)}):_vm._e(),(_vm.step == 1)?_c('validation-observer',{key:2,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form-group',{attrs:{"label":"Empresa","label-for":"register-empresa"}},[_c('validation-provider',{attrs:{"name":"Empresa","vid":"empresa","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","id":"register-empresa","name":"register-empresa","state":errors.length > 0 ? false : null,"placeholder":"Nombre de la empresa"},model:{value:(_vm.user.brand),callback:function ($$v) {_vm.$set(_vm.user, "brand", $$v)},expression:"user.brand"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Envios Mensuales","description":"Cantidad aproximada de envíos al mes.","label-for":"monthly_shipments"}},[_c('validation-provider',{attrs:{"name":"Envios Mensuales","vid":"monthly_shipments","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"size":"sm","id":"monthly_shipments","name":"monthly_shipments","options":_vm.monthly_shipments,"state":errors.length > 0 ? false : null},model:{value:(_vm.user.monthly_shipments),callback:function ($$v) {_vm.$set(_vm.user, "monthly_shipments", $$v)},expression:"user.monthly_shipments"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Provincia","label-for":"register-provincia"}},[_c('validation-provider',{attrs:{"name":"Provincia","vid":"provincia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"size":"sm","id":"register-provincia","name":"register-provincia","options":_vm.provinces,"state":errors.length > 0 ? false : null},on:{"input":_vm.fetchLocations},model:{value:(_vm.user.province_id),callback:function ($$v) {_vm.$set(_vm.user, "province_id", $$v)},expression:"user.province_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Localidad","label-for":"register-localidad"}},[_c('validation-provider',{attrs:{"name":"Localidad","vid":"localidad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"size":"sm","id":"register-localidad","name":"register-localidad","options":_vm.locations,"state":errors.length > 0 ? false : null},model:{value:(_vm.user.location_id),callback:function ($$v) {_vm.$set(_vm.user, "location_id", $$v)},expression:"user.location_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('b-row',[_c('b-col',[(_vm.step == 1)?_c('b-button',{staticClass:"md-secondary md-width rounded-pill w-100 py-1",on:{"click":function($event){_vm.step -= 1}}},[_vm._v("VOLVER")]):_vm._e()],1),_c('b-col',[(_vm.step == 1)?_c('b-button',{staticClass:"md-primary md-width rounded-pill w-100 py-1",attrs:{"disabled":invalid,"type":"submmit"}},[_vm._v("SIGUIENTE")]):_vm._e()],1)],1)],1)]}}],null,false,4035111107)}):_vm._e(),(_vm.step == 2)?_c('div',{staticClass:"mb-5 mt-1"},[_c('center',[_c('img',{staticClass:"mt-1 mb-2",attrs:{"src":"/img/step/check1.png"}}),_c('div',{staticClass:"mb-5"},[_c('h3',[_vm._v("¡Felicidades!")]),_c('p',[_vm._v("Tu registro fue exitoso. Te hemos enviado un email a tu correo para verificar la cuenta")])])])],1):_vm._e()],1)],1),(_vm.step == 2)?_c('b-button',{staticClass:"md-primary md-width rounded-pill w-100 py-1 mb-2 mt-5",on:{"click":function($event){return _vm.go_login()}}},[_vm._v("FINALIZAR")]):_vm._e()],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }